var exports = {};
var ESCAPE_LOOKUP = {
  "&": "\\u0026",
  ">": "\\u003e",
  "<": "\\u003c",
  "\u2028": "\\u2028",
  "\u2029": "\\u2029"
};
var ESCAPE_REGEX = /[&><\u2028\u2029]/g;

function escaper(match) {
  return ESCAPE_LOOKUP[match];
}

exports = function (obj) {
  return JSON.stringify(obj).replace(ESCAPE_REGEX, escaper);
};
/***/


var TERMINATORS_LOOKUP = {
  "\u2028": "\\u2028",
  "\u2029": "\\u2029"
};
var TERMINATORS_REGEX = /[\u2028\u2029]/g;

function sanitizer(match) {
  return TERMINATORS_LOOKUP[match];
}

exports.sanitize = function (str) {
  return str.replace(TERMINATORS_REGEX, sanitizer);
};

export default exports;
export const sanitize = exports.sanitize;